/* h1,
h2,
h3,
h4,
h5,
h6 {
  color: rgba(255, 255, 255, 1);
} */

/* Fix for https://github.com/mui-org/material-ui/issues/13594 */
body {
  padding: 0 !important;
  line-height: 1.2;
  height: 100vh;
  overflow: hidden;
}

.bn-onboard-custom.bn-onboard-modal {
  font-family: "DM Sans";
  background-color: rgba(0, 0, 0, 0);
  backdrop-filter: blur(10px);
  z-index: 2000;
  padding: 30px;
}

.bn-onboard-custom.bn-onboard-modal-content {
  background: #1c2028 !important;
  padding: 30px;
}

.bn-onboard-prepare-button {
  border-color: #a448ff !important;
  color: #a448ff !important;
  border-radius: 6px !important;
}

.bn-onboard-prepare-button:hover {
  border-color: #c86cff !important;
  color: #c86cff !important;
  background-color: transparent !important;
}

.bn-onboard-icon-button {
  background: #13171d !important;
  border-radius: 10px !important;
}

.bn-onboard-custom.bn-onboard-select-wallet-info {
  color: #94f83e !important;
  font-size: 16px !important;
}

.bn-onboard-custom.bn-onboard-icon-button img,
.bn-onboard-custom.bn-onboard-icon-button svg {
  width: 24px;
  height: 24px;
}

.bn-onboard-custom.bn-onboard-modal-content-header-heading:before {
  content: "Connect Wallet";
  font-size: 24px;
}

.bn-onboard-custom.bn-onboard-modal-content-header-heading {
  font-size: 0;
}

.bn-onboard-modal-content-header-icon {
  background: none !important;
  color: #a448ff !important;
}

.bn-onboard-custom.bn-onboard-modal-content-header-icon {
  background-image: url("./media/Wallet.svg") !important;
  background-repeat: no-repeat !important;
  width: 32px;
  height: 32px;
  margin-right: 20px;
  padding: 0 !important;
  border-radius: 0 !important;
}

.bn-onboard-custom.bn-onboard-modal-content-header-icon svg {
  display: none;
}

.bn-onboard-custom.bn-onboard-modal-select-wallets {
  flex-direction: column;
  width: 100%;
}

.bn-onboard-custom.bn-onboard-modal-select-wallets > * {
  width: 100%;
  padding: 0 !important;
}

.bn-onboard-custom.bn-onboard-icon-button {
  width: 100%;
}

/* Chrome, Safari, Edge, Opera */
/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
/* input[type="number"] {
  -moz-appearance: textfield;
} */
